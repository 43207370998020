<script>
import formService from "@/services/form";
import { s3Url, theme, isFormPrintingEnabled } from "@/services/config";
import fileUploadConfigService from "@/services/fileupload-config";
import DiagnosisSelect from "@/components/ui/DiagnosisSelect";
import { isIndicationsEnabled } from "@/services/config";

import FileUpload from "@/components/ui/FileUpload";

export default {
  name: "ModalPatientEventForm",

  props: {
    selectedPatientForm: {
      type: Object,
    },
    patient: {
      type: Object,
    },
    isModal: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    FileUpload,
    DiagnosisSelect,
  },

  data() {
    return {
      forms: [],
      selectedFormId: "",
      selectedForm: "",
      isLoading: false,
      isNewPatientForm: true,
      hasFiles: false,
      s3Url,
      theme,
      patientFormId: "",
      isIndicationsEnabled,
      showConfirmModal: false,
      isPosting: false,
      isFormPrintingEnabled,
    };
  },

  created() {
    if (this.selectedPatientForm?._id) {
      this.isNewPatientForm = false;
    } else {
      this.getForms();
    }

    document.addEventListener("keyup", this.onKeyUp);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.onKeyUp);
  },

  computed: {
    uploadMediaEndpoint() {
      return fileUploadConfigService.getFormFilesEndpoint(this.patientFormId);
    },
  },

  watch: {
    selectedFormId() {
      const selectedForm = this.forms.find(
        (elem) => elem._id === this.selectedFormId
      );
      this.selectedForm = selectedForm;
    },
  },

  methods: {
    async getForms() {
      this.isLoading = true;
      try {
        const result = await formService.getForms();
        this.forms = result;
      } finally {
        this.isLoading = false;
      }
    },

    handleFormPrinting() {
      localStorage.selectedPatientForm = JSON.stringify(
        this.selectedPatientForm
      );
      localStorage.patient = JSON.stringify(this.patient);
      const route = this.$router.resolve({
        name: "event-print",
      });
      window.open(route.resolved.fullPath);
    },

    onKeyUp(event) {
      const esc = 27;
      if (event.keyCode == esc) {
        this.closeModal();
      }
    },

    closeModal() {
      this.isPosting = false;
      this.isLoading = false;
      this.$emit("closeModal");
    },

    confirmCloseState() {
      if (this.selectedForm.status === "closed") {
        this.showConfirmModal = true;
        return;
      }
      return this.createNewForm();
    },

    closeConfirmModal() {
      this.showConfirmModal = false;
    },

    async createNewForm() {
      // TODO: Improve this validation
      this.isPosting = true;

      const completedForm = {
        ...this.selectedForm,
        form: this.selectedFormId,
        patient: this.patient._id,
        createdBy: this.$store.state.user,
      };

      formService
        .postPatientForm(completedForm)
        .then((createdForm) => {
          this.patientFormId = createdForm._id;

          this.$bus.$emit("form-created", {
            ...createdForm,
            createdBy: this.$store.state.user,
          });

          if (createdForm.status === "closed") {
            this.$bus.$emit("episode-closed");
          }

          if (this.hasFiles) {
            this.handleUploadFiles();
          } else {
            this.closeModal();
          }
        })
        .catch(() => {
          this.isPosting = false;
        });
    },

    getDropdownOptions() {
      return {
        method: "put",
        uploadMultiple: true,
        maxFiles: 3,
        maxFilesize: 5,
        acceptedFiles: "image/*,application/pdf",
      };
    },

    handleUploadFiles() {
      this.$refs.fileUpload && this.$refs.fileUpload.startUpload();
    },

    onFileAdded() {
      this.hasFiles = true;
    },

    onFileRemoved() {
      this.hasFiles = false;
    },

    calculateQualifierMeasurement(section) {
      const qualification = section.questions.reduce((sum, question) => {
        const answerValue = question.answers.find(
          (answer) => answer.value === question.selected
        );
        return sum + (answerValue ? answerValue.qualifierValue : 0);
      }, 0);

      let qualificationMeasurement = {};

      section.qualifier.measurement.forEach((elem) => {
        if (qualification >= elem.min && qualification <= elem.max) {
          qualificationMeasurement = elem;
        }
      });

      return {
        ...qualificationMeasurement,
        qualification,
        target: section.qualifier.target,
      };
    },

    getQualifierName(section) {
      const { name, qualification, target } =
        this.calculateQualifierMeasurement(section);

      return `${name} (${qualification}/${target})`;
    },

    getQualifierStyle(section) {
      const { style } = this.calculateQualifierMeasurement(section);
      return style && JSON.parse(JSON.stringify(style));
    },
  },
};
</script>

<template lang="pug">
form.modal-event-form(@submit.prevent="confirmCloseState" :class="isModal && 'modal'")
  header.modal__header
    h2.modal__title {{isNewPatientForm ? 'Nuevo Estado' : selectedForm.name}}
    .modal__actions
        el-button(type="info", @click="closeModal()") Cancelar
        el-button.border(
          v-if="isNewPatientForm"
          type="primary",
          native-type="submit",
          :loading="isPosting"
        ) Agregar
        el-button.border(
          v-if="!isNewPatientForm && isFormPrintingEnabled"
          @click="handleFormPrinting()"
        ) Imprimir

  .modal__content(v-if='isNewPatientForm')
    .modal__block
      .modal__section
        .modal__sign.sign(v-if="isModal")
          .sign__icon
            micon(name="description")
          h3.sign__title Estado
        article(:class="isModal && 'modal__fields'")
          .modal__row
            fieldset.modal__field
              label.label Estado:
              el-select(
                v-model="selectedFormId",
                filterable,
                default-first-option
                :no-data-text="isLoading ? 'Cargando' : 'Sin Datos'"
              )
                el-option(
                  v-for="form in forms",
                  :key="form._id",
                  :label="form.name",
                  :value="form._id"
                )

          .modal__row(v-if="!selectedForm")
            fieldset.modal__row
              h2.status Seleccionar Estado

          .modal__row(v-else)
            .fieldset.modal__field
              fieldset.modal__field(v-if='selectedForm.answeredAtEnable')
                label.label Fecha:
                el-date-picker(v-model='selectedForm.answeredAt' placeholder="Selecciona una fecha" type="datetime")

              .modal__field(v-for='section in selectedForm.sections' :key="section.name")
                .section-titles
                  fieldset.modal__row
                    h2 {{ section.name }}
                    
                  fieldset.modal__field(v-if="section.qualifier")
                    h4 {{section.qualifier.name}} : 
                      span(:style="getQualifierStyle(section)") {{ isNewPatientForm ? getQualifierName(section) : section.qualifierResult }}

                .modal__row(v-for='question in section.questions' :key="question.name")

                  fieldset.modal__field(v-if="question.type==='select' && !question.action")
                    label.label {{ question.name }}
                    el-select(v-model="question.selected")
                      el-option(
                        v-for="answer in question.answers"
                        :value="answer.value"
                        :key="answer.value"
                        :label="answer.value"
                      )
                    span(v-if="question.observationEnable")
                      label.label(:style="{'margin-top': '10px'}") Especifique
                      el-input(
                        v-model="question.observation",
                      )

                  fieldset.modal__field(v-if="question.action && question.action.type === 'indications' && question.action.subtype === 'diagnosis' && isIndicationsEnabled")
                    label.label {{ question.name }}
                    DiagnosisSelect(
                      :modelValue="question.selected"
                      @update:modelValue="diagnosis => question.selected = diagnosis"
                    )
                    span(v-if="question.observationEnable")
                      label.label(:style="{'margin-top': '10px'}") Especifique
                      el-input(
                        v-model="question.observation",
                      )

                  fieldset.modal__field(v-if="question.type==='input'")
                    label.label {{ question.name }}
                    el-input(v-model="question.selected")
                    span(v-if="question.observationEnable")
                      label.label(:style="{'margin-top': '10px'}") Especifique
                      el-input(
                        v-model="question.observation",
                      )

                  fieldset.modal__field(v-if="question.type==='inputNumber'")
                    label.label {{ question.name }}
                    el-input(v-model="question.selected" type="number" step=".01")
                    span(v-if="question.observationEnable")
                      label.label(:style="{'margin-top': '10px'}") Especifique
                      el-input(
                        v-model="question.observation",
                      )
                
                  fieldset.modal__field(v-if="question.type==='checkbox'")
                    label.label {{ question.name }}
                    el-checkbox-group(v-model="question.selected")
                      el-checkbox(
                        v-for="answer in question.answers"
                        :value="answer.value"
                        :key="answer.value"
                        :label="answer.value"
                      )
                    span(v-if="question.observationEnable")
                      label.label(:style="{'margin-top': '10px'}") Especifique
                      el-input(
                        v-model="question.observation",
                      )

                  fieldset.modal__field(v-if="question.type ==='radio'")
                    label.label {{ question.name }}
                    el-radio-group(v-model="question.selected")
                      el-radio(
                        readonly
                        v-for="answer in question.answers",
                        :key="answer.value",
                        :label="answer.value"
                        :class="answer.style && answer.style.color ? `border-${answer.style.color}` : ''",
                      )
                    span(v-if="question.observationEnable")
                      label.label(:style="{'margin-top': '10px'}") Especifique
                      el-input(
                        :disabled="question.selected === 'No'"
                        v-model="question.observation",
                      )

                  fieldset.modal__field(v-if="question.type==='textarea'")
                    label.label {{question.name}}
                    el-input(
                      v-model="question.selected",
                      :placeholder="`${question.name}...`",
                      type="textarea"
                    )
                    span(v-if="question.observationEnable")
                      label.label(:style="{'margin-top': '10px'}") Especifique
                      el-input(
                        v-model="question.observation",
                      )
                
                fieldset.modal__field(v-if="section.qualifier")
                  h1(:style="getQualifierStyle(section)") {{section.qualifier.name}} : {{ isNewPatientForm ? getQualifierName(section) : section.qualifierResult }}

              fieldset.modal__field(v-if="selectedForm.fileSection.enable")
                label.label {{ selectedForm.fileSection.name || "Archivos adjuntos" }} (3 máx):
                file-upload(
                  ref="fileUpload",
                  :url="uploadMediaEndpoint",
                  :dropzone-options="getDropdownOptions()"
                  @file-added="onFileAdded",
                  @files-removed="onFileRemoved",
                  @queue-complete="closeModal"
                )

  .modal__content(v-else)
      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="description")
            h3.sign__title Estado
          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label Estado:
                h1 {{selectedPatientForm.name}}
            .modal__row
              .fieldset.modal__field
                fieldset.modal__field(v-if='selectedPatientForm.answeredAt')
                  label.label Fecha:
                  el-date-picker(readonly :value='selectedPatientForm.answeredAt' type="datetime")

                .modal__field(v-for='section in selectedPatientForm.sections' :key="section.name")
                  fieldset.modal__row
                    h2 {{ section.name }}
                  
                  .modal__row(v-for='question in section.questions' :key="question.name")

                    fieldset.modal__field(v-if="question.type==='select' && !question.action")
                      label.label {{ question.name }}
                      el-input(:value="question.selected" readonly placeholder="Sin datos")
                      span(v-if="question.observationEnable")
                        label.label(:style="{'margin-top': '10px'}") Especifique
                        el-input(
                          :value="question.observation",
                        )

                    fieldset.modal__field(v-if="question.action && question.action.type === 'indications' && question.action.subtype === 'diagnosis'")
                      label.label {{ question.name }}
                      DiagnosisSelect(
                        :modelValue="question.selected"
                        @update:modelValue="diagnosis => question.selected = diagnosis"
                        readonly
                      )
                      span(v-if="question.observationEnable")
                        label.label(:style="{'margin-top': '10px'}") Especifique
                        el-input(
                          :value="question.observation",
                        )

                    fieldset.modal__field(v-if="question.type==='input'")
                      label.label {{ question.name }}
                      el-input(:value="question.selected" readonly placeholder="Sin datos")
                      span(v-if="question.observationEnable")
                        label.label(:style="{'margin-top': '10px'}") Especifique
                        el-input(
                          :value="question.observation",
                        )

                    fieldset.modal__field(v-if="question.type==='inputNumber'")
                      label.label {{ question.name }}
                      el-input(:value="question.selected" readonly placeholder="Sin datos")
                      span(v-if="question.observationEnable")
                        label.label(:style="{'margin-top': '10px'}") Especifique
                        el-input(
                          :value="question.observation",
                        )
                
                    fieldset.modal__field(v-if="question.type==='checkbox'")
                      label.label {{ question.name }}
                      el-checkbox-group(:value="question.selected")
                        el-checkbox(
                          v-for="answer in question.answers"
                          :value="answer.value"
                          :key="answer.value"
                          :label="answer.value"
                        )
                      span(v-if="question.observationEnable")
                        label.label(:style="{'margin-top': '10px'}") Especifique
                        el-input(
                          :value="question.observation",
                        )

                    fieldset.modal__field(v-if="question.type ==='radio'")
                      label.label {{ question.name }}
                      el-radio-group(:value="question.selected")
                        el-radio(
                          readonly
                          v-for="answer in question.answers",
                          :key="answer.value",
                          :label="answer.value"
                          :class="answer.style && answer.style.color ? `border-${answer.style.color}` : ''",
                        )
                      span(v-if="question.observationEnable")
                        label.label(:style="{'margin-top': '10px'}") Especifique
                        el-input(
                          :value="question.observation",
                        )

                    fieldset.modal__field(v-if="question.type==='textarea'")
                      label.label {{question.name}}
                      el-input(
                        readonly
                        :value="question.selected",
                        type="textarea"
                        placeholder="Sin datos"
                      )
                      span(v-if="question.observationEnable")
                        label.label(:style="{'margin-top': '10px'}") Especifique
                        el-input(
                          :value="question.observation",
                        )

                  fieldset.modal__field(v-if="section.qualifier")
                    h1(:style="getQualifierStyle(section)") {{section.qualifier.name}} : {{ isNewPatientForm ? getQualifierName(section) : section.qualifierResult }}

                fieldset.modal__field(v-if="selectedPatientForm.fileSection.files.length")
                  label.label {{ selectedPatientForm.fileSection.name || "Archivos adjuntos" }}
                  .attached-files
                    .file(
                      v-for="file in selectedPatientForm.fileSection.files"
                      v-bind:class="{ image: file.mimeType.includes('image') }"
                    )
                      a(:href="`${s3Url}/${file.key}`" target="_blank" rel="noopener noreferrer")
                        img(
                          v-if="file.mimeType.includes('image')"
                          :src="`${s3Url}/${file.key}`"
                          alt="Archivo adjunto"
                        )
                        span(v-else) {{ getFileName(file.key) }}
              


  el-dialog( 
    title="¿Está seguro que desea cambiar a estado Cerrado?"
    custom-class="el-dialog__body"
    :visible.sync="showConfirmModal"
    :before-close="closeConfirmModal"
    append-to-body
  )
    span(class="show-confirm-modal-footer")
      el-button(@click="closeConfirmModal") Cancelar
      el-button(type="primary" @click="createNewForm") Confirmar

</template>

<style lang="scss">
article {
  width: 100%;
}

.section-titles {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.modal-event-form {
  .el-radio {
    width: 100%;

    &.border-green {
      .el-radio__input .el-radio__inner {
        border: 1px solid $ocean-green;
      }
      .el-radio__input.is-checked .el-radio__inner {
        background-color: $ocean-green;
      }
    }

    &.border-yellow {
      .el-radio__input .el-radio__inner {
        border: 1px solid $corn;
      }
      .el-radio__input.is-checked .el-radio__inner {
        background-color: $corn;
      }
    }

    &.border-red {
      .el-radio__input .el-radio__inner {
        border: 1px solid $flamingo;
      }
      .el-radio__input.is-checked .el-radio__inner {
        background-color: $flamingo;
      }
    }
  }
  .option-field.el-textarea {
    margin-left: 24px;
  }
}
.show-confirm-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.event-detail-modal {
  h2 {
    font-weight: bold;
  }

  h3 {
    margin: 0 0 20px;
  }

  .footer {
    display: flex;
    flex-direction: row;
  }

  .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .description {
      word-break: break-word;
      font-weight: bold;
      color: $primary;
      margin-bottom: 10px;
    }

    .response {
      margin-left: 20px;
      display: list-item;
      list-style-type: "→ ";
    }
    .attached-files {
      display: flex;
      flex-wrap: wrap;
      .file {
        width: 100%;
        margin: 0 15px 15px 0;

        &:not(.image) a::before {
          content: "→ ";
        }
      }
      .file.image {
        width: 150px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        img {
          object-fit: cover;
          height: -webkit-fill-available;
        }
      }
    }
  }
}
</style>
