<script>
import agendaService from "@/services/agenda";
import Spinner from "@/components/ui/Spinner";
import { mapGetters } from "vuex";
import leave from "@/mixins/leave";
import helperService from "@/services/helper";
import doctorService from "@/services/doctor";
import userService from "@/services/user";
import {
  WEEK_DAYS_OPTIONS,
  SLOT_ATTENTION_TYPES,
  SLOT_PERIODICITY,
} from "@/services/constants";
import ModalInstitution from "@/components/settings/institution/ModalInstitution";
import { format, endOfDay, subMilliseconds, addMilliseconds } from "date-fns";

export default {
  name: "ModalAgenda",

  props: {
    selectedAgenda: {
      type: Object,
    },
    fetchedInstitutions: {
      type: Array,
    },
  },

  components: {
    Spinner,
    ModalInstitution,
  },

  mixins: [leave],

  data() {
    return {
      agenda: {
        institution: "",
        doctor: "",
        medicalSpecialties: [],
        startDate: new Date(),
        configurations: [
          {
            weekDays: [],
            attentionType: [],
            attentionDuration: 30,
          },
        ],
        isVisibleByPatient: true,
        scheduledUnavailabilities: [],
      },
      isPosting: false,
      doctors: [],
      medicalSpecialties: [],
      institutions: this.fetchedInstitutions,

      showDayError: false,
      dayOptions: WEEK_DAYS_OPTIONS,
      periodicityOtions: SLOT_PERIODICITY,
      attentionTypeOptions: SLOT_ATTENTION_TYPES,
      isUpdate: false,
      isLoading: false,
      showScheduledUnavailabilityError: false,
      isDeleting: false,
      isInstitutionModalVisible: false,
    };
  },

  computed: {
    ...mapGetters(["isDoctor", "user"]),

    isValidScheduledUnavailavilities() {
      if (this.agenda.scheduledUnavailabilities?.length) {
        return this.agenda.scheduledUnavailabilities?.every(
          (scheduledUnavailability) =>
            scheduledUnavailability.startsAt && scheduledUnavailability.endsAt
        );
      } else {
        return true;
      }
    },
  },

  watch: {
    "agenda.institution"() {
      if (!this.isUpdate) {
        if (!this.isDoctor) {
          this.agenda.doctor = "";
        }
        this.agenda.medicalSpecialties = [];

        if (this.agenda.institution) {
          this.getDoctorsByInstitution(this.agenda.institution);
        }
      }
    },

    "agenda.doctor"() {
      if (!this.isUpdate) {
        this.agenda.medicalSpecialties = [];
        if (this.agenda.doctor) {
          this.getMedicalSpecialtiesByDoctor(this.agenda.doctor);
        }
      }
    },
  },

  created() {
    if (this.selectedAgenda._id) {
      const parsedConfigurations = this.selectedAgenda.configurations.map(
        (elem) => {
          const parsedStartHour = format(new Date(elem.startHour), "HH:mm");

          let parsedEndHour = format(
            addMilliseconds(new Date(elem.endHour), 1),
            "HH:mm"
          );

          // workaround
          if (parsedEndHour === "00:00") {
            parsedEndHour = "24:00";
          }
          // workaround

          return {
            ...elem,
            startHour: parsedStartHour,
            endHour: parsedEndHour,
          };
        }
      );

      this.doctors = [this.selectedAgenda.doctor];
      this.medicalSpecialties = this.selectedAgenda.medicalSpecialties;
      this.agenda = {
        ...this.selectedAgenda,
        medicalSpecialties: this.selectedAgenda.medicalSpecialties.map(
          (medicalSpecialty) => medicalSpecialty._id
        ),
        doctor: this.selectedAgenda.doctor._id,
        institution: this.selectedAgenda.institution._id,
        configurations: parsedConfigurations,
        ...(this.selectedAgenda.startDate && {
          startDate: new Date(this.selectedAgenda.startDate),
        }),
        ...(this.selectedAgenda.endDate && {
          endDate: new Date(this.selectedAgenda.endDate),
        }),
        scheduledUnavailabilities:
          this.selectedAgenda.scheduledUnavailabilities || [],
      };
      this.isUpdate = true;
    }

    this.$bus.$on("institution-created", (institution) => {
      this.institutions.push(institution);
    });
  },

  mounted() {
    document.addEventListener("keyup", this.escape);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.escape);
  },

  methods: {
    isAttentionTypeAvailable(attentionType) {
      return this.agenda.configurations.some((configuration) =>
        configuration.attentionType.includes(attentionType)
      );
    },

    close() {
      this.$emit("close");
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    escape(event) {
      if (event.keyCode == 27) this.close();
    },

    parseHoursIntoDate(hours) {
      const [hour, minutes] = hours.split(":");
      const date = new Date();
      date.setHours(hour);
      date.setMinutes(minutes);
      date.setSeconds("00");
      date.setMilliseconds("000");
      return date;
    },

    submit() {
      this.createOrUpdateAgenda();
    },

    async getDoctorsByInstitution(institutionId) {
      this.isLoading = true;
      try {
        const doctors = await userService.getDoctorsByInstitution(
          institutionId
        );
        this.doctors = doctors;
      } finally {
        this.isLoading = false;
        if (this.isDoctor) {
          this.agenda.doctor = this.user._id;
        }
      }
    },

    async getMedicalSpecialtiesByDoctor(doctorId) {
      const doctorDetails = await doctorService.getDoctorDetails(doctorId);

      this.medicalSpecialties = doctorDetails.doctor?.medicalSpecialties;
    },

    async createOrUpdateAgenda() {
      const message = `La acción puede demorar unos minutos. <br/> No cierre la ventana <br/> ¿Desea continuar?`;

      this.$confirm(message, "Confirmar", {
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(async () => {
        this.isPosting = true;

        const parsedConfigurations = this.agenda.configurations.map((elem) => {
          const parsedEndHour = subMilliseconds(
            this.parseHoursIntoDate(elem.endHour),
            1
          );
          const parsedStartHour = this.parseHoursIntoDate(elem.startHour);

          return {
            ...elem,
            startHour: parsedStartHour,
            endHour: parsedEndHour,
          };
        });

        const parsedAgenda = {
          ...this.agenda,
          endDate: endOfDay(this.agenda.endDate),
          configurations: parsedConfigurations,
        };

        if (!this.isUpdate) {
          try {
            const createdAgenda = await agendaService.createAgenda(
              parsedAgenda
            );
            this.$bus.$emit("agenda-created", createdAgenda);
            this.close();
          } finally {
            this.isPosting = false;
          }
        } else {
          try {
            const updatedAgenda = await agendaService.updateAgenda(
              parsedAgenda
            );
            this.$bus.$emit("agenda-updated", updatedAgenda);
            this.close();
          } finally {
            this.isPosting = false;
          }
        }
      });
    },

    getModalName() {
      if (this.isUpdate) {
        return "Actualizar Agenda";
      }
      return "Nueva Agenda";
    },

    addDay() {
      this.showDayError = false;
      const lastDay =
        this.agenda.configurations[this.agenda.configurations.length - 1];
      if (
        lastDay &&
        (!lastDay.weekDays.length ||
          !lastDay.attentionType.length ||
          !lastDay.periodicity ||
          !lastDay.startHour ||
          !lastDay.endHour ||
          !lastDay.attentionDuration)
      ) {
        this.showDayError = true;
        return;
      }
      this.agenda.configurations.push({
        weekDays: [],
        attentionType: [],
        attentionDuration: 30,
      });
    },

    removeDay(index) {
      this.showDayError = false;
      this.agenda.configurations.splice(index, 1);
    },

    addScheduledUnavailability() {
      if (this.isValidScheduledUnavailavilities) {
        this.agenda.scheduledUnavailabilities.push({
          startsAt: undefined,
          endsAt: undefined,
        });
      }
    },

    removeScheduledUnavailability(index) {
      this.agenda.scheduledUnavailabilities.splice(index, 1);
    },

    deleteAgenda() {
      this.$confirm(
        "¿Está seguro de eliminar la agenda y todas sus citas? Esto eliminará de forma permanente la agenda y todas las citas asociadas.",
        "Eliminar",
        {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      ).then(() => {
        this.isDeleting = false;
        agendaService.delete(this.selectedAgenda._id).then(() => {
          this.$bus.$emit("agenda-deleted", this.selectedAgenda);
          this.isDeleting = false;
          this.close();
        });
      });
    },

    showInstitutionModal() {
      this.isInstitutionModalVisible = true;
    },

    closeInstitutionModal() {
      this.isInstitutionModalVisible = false;
    },
  },
};
</script>

<template lang="pug">
ValidationObserver(v-slot="{handleSubmit}")
  form.modal(@submit.prevent="handleSubmit(submit)")
    header.modal__header
      h2.modal__title {{ getModalName() }}
      .modal__actions
        el-button(v-if='!agenda._id' type='info' @click="showInstitutionModal()") Crear consultorio
        el-button(type="info" @click="close()") Cancelar
        el-button.border(type="primary" native-type="submit" :loading="isPosting") Guardar
    p.modal__subtitle Los campos con (*) son obligatorios
    .modal__content
      // Personal Info Section
      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="person")
            h3.sign__title Información Agenda
          article.modal__fields
            .modal__row
              fieldset.modal__field
                  label.label * Consultorio
                  ValidationProvider(name="Institución", rules="required", v-slot="{ errors }")
                    el-select(
                      v-model="agenda.institution"
                      placeholder="Institución"
                      filterable
                      clearable
                      required
                      :disabled="isUpdate"
                    )   
                      el-option(
                        v-for="institution in institutions"
                        :key="institution._id"
                        :label="institution.name"
                        :value="institution._id"
                      )
                    span.has-error {{ errors[0] }}

              fieldset.modal__field(v-if="!isDoctor")
                label.label * Doctor
                ValidationProvider(name="Doctor", rules="required", v-slot="{ errors }")
                  el-select(
                    v-model="agenda.doctor"
                    placeholder="Médico"
                    filterable
                    clearable
                    required
                    :no-data-text="agenda.institution ? 'No hay doctores para el hospital seleccionado' : 'Seleccione un hospital'"
                    :disabled="isUpdate"
                  )   
                    el-option(
                      v-for="doctor in doctors"
                      :key="doctor._id"
                      :label="getFullName(doctor)"
                      :value="doctor._id"
                    )
                  span.has-error {{ errors[0] }}  
            
              fieldset.modal__field
                label.label * Especialidad
                ValidationProvider(name="Especialidad", rules="required", v-slot="{ errors }")
                  el-select(
                    v-model="agenda.medicalSpecialties"
                    placeholder="Especialidad"
                    multiple
                    filterable
                    clearable
                    required
                    :no-data-text="agenda.doctor ? 'El doctor no tiene especialidades' : 'Seleccione un doctor'"
                    :disabled="isUpdate"
                  )   
                    el-option(
                      v-for="medicalSpecialty in medicalSpecialties"
                      :key="medicalSpecialty._id"
                      :label="medicalSpecialty.name"
                      :value="medicalSpecialty._id"
                    )
                  span.has-error {{ errors[0] }}  

            .modal__row
              fieldset.modal__field
                label.label Inicia en:
                ValidationProvider(name="Fecha Inicio" :rules="!isUpdate ? 'includeToday' : ''" v-slot="{ errors }" vid="startDate")
                  el-date-picker(v-model="agenda.startDate" v-tooltip.bottom="'Fecha de inicio de la agenda'")
                  span.has-error {{ errors[0] }}

              fieldset.modal__field
                label.label Finaliza en (no obligatorio):
                ValidationProvider(name="Fecha Finalización" rules="greaterThan:@startDate" v-slot="{ errors }")
                  el-date-picker(v-model="agenda.endDate" v-tooltip.bottom="'Fecha de finalización de la agenda'")
                  span.has-error {{ errors[0] }}

              fieldset.modal__field
                label.label * Ventana (días):
                ValidationProvider(name="Ventana" rules="required|integer|min_value:1" v-slot="{ errors }")
                  el-input(v-model="agenda.windowPeriod" v-tooltip.bottom="'Período de generación de citas'")
                  span.has-error {{ errors[0] }}

            .modal__row
              fieldset.modal__field
                el-checkbox(v-model="agenda.isVisibleByPatient" label="Citas visibles para el paciente")
                
      // Day section
      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="calendar")
            h3.sign__title Dias
          article.modal__fields
            .days(v-for="(day, index) in agenda.configurations")
              .modal__row
                fieldset.modal__field
                  label.label Día/s: {{index + 1}}
                  ValidationProvider(:name='`Dia ${index +1}`', rules="required", v-slot="{ errors }")
                    el-select(
                      v-model="day.weekDays"
                      placeholder='Seleccione un día'
                      filterable
                      multiple
                      clearable
                      required
                    )
                      el-option(
                        v-for="day in dayOptions"
                        :key="day.value"
                        :label="day.name"
                        :value="day.value"
                      )
                    span.has-error {{ errors[0] }}

                fieldset.modal__field
                  label.label Periodicidad:
                  ValidationProvider(:name='`Periodicidad ${index +1}`', rules="required", v-slot="{ errors }")
                    el-select(
                        v-model="day.periodicity"
                        required
                        clearable
                      )
                      el-option(
                        v-for="periodicityOption in periodicityOtions"
                        :key="periodicityOption.name"
                        :label="periodicityOption.name"
                        :value="periodicityOption.value"
                      )
                    span.has-error {{ errors[0] }}      
                    
                fieldset.modal__field
                  label.label Modalidad:
                  ValidationProvider(:name='`Modalidad ${index +1}`', rules="required", v-slot="{ errors }")
                    el-checkbox-group(
                      v-model="day.attentionType"
                      required
                      clearable
                    )
                      el-checkbox(
                        v-for="attentionTypeOption in attentionTypeOptions"
                        :key="attentionTypeOption.name"
                        :label="attentionTypeOption.value"
                      ) {{ attentionTypeOption.name}}
                    span.has-error {{ errors[0] }}

              .modal__row
                fieldset.modal__field
                  label.label Horario Inicio:
                  ValidationProvider(:name='`Horario Inicio ${index +1}`', rules="required", v-slot="{ errors }" :vid="`start-${index}`")
                    el-time-select(v-model="day.startHour" :picker-options="{step:'00:15', start:'00:00', end:'24:00'}")
                    span.has-error {{ errors[0] }}

                fieldset.modal__field
                  label.label Horario Fin:
                  ValidationProvider(:name='`Horario Fin ${index +1}`', :rules="`required|greaterThan:@start-${index}`", v-slot="{ errors }")
                    el-time-select(v-model="day.endHour" :picker-options="{step:'00:15', start:'00:00', end:'24:00'}")
                    span.has-error {{ errors[0] }}
              
                fieldset.modal__field
                  label.label Duración Cita (min): 
                  ValidationProvider(:name='`Duración Cita ${index +1}`', rules="required|integer|min_value:1", v-slot="{ errors }")
                    el-input(v-model="day.attentionDuration")
                    span.has-error {{ errors[0] }}


              .modal__row
                fieldset.modal__field
                  small
                    a(@click="removeDay(index)") &times; Eliminar Dia/s {{ index + 1 }}

            .modal__row
              fieldset.modal__field
                span.days-error(v-if="agenda.configurations && showDayError") Debes completar el/los dia/s para poder agregar uno nuevo
            
            .modal__row.days__add
              fieldset.modal__field  
                a(@click="addDay") + Agregar Dia/s

      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="money")
            h3.sign__title Aranceles
          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label Precio virtual:
                ValidationProvider(name="Precio virtual" rules="integer|min_value:1" v-slot="{ errors }")
                  el-input(v-model="agenda.virtualValue" :disabled="!isAttentionTypeAvailable('virtual')")
                    i(slot="prefix" class="el-input__icon el-icon-money")
                  span.has-error {{ errors[0] }}
              fieldset.modal__field
                label.label Precio presencial:
                ValidationProvider(name="Precio presencial" rules="integer|min_value:1" v-slot="{ errors }")
                  el-input(v-model="agenda.inPersonValue" :disabled="!isAttentionTypeAvailable('in-person')")
                    i(slot="prefix" class="el-input__icon el-icon-money")
                  span.has-error {{ errors[0] }}
                  
      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="calendar")
            h3.sign__title Ausencias programadas
          article.modal__fields
            .days(v-for="(scheduledUnavailability, index) in agenda.scheduledUnavailabilities")
              .modal__row
                fieldset.modal__field
                  label.label Inicia en:
                  el-date-picker(type="datetime" v-model="scheduledUnavailability.startsAt" :disabled="!!scheduledUnavailability._id")

                fieldset.modal__field
                  label.label Finaliza en:
                  el-date-picker(type="datetime" v-model="scheduledUnavailability.endsAt" :disabled="!!scheduledUnavailability._id" default-time="23:59:59")

              .modal__row(v-if="!scheduledUnavailability._id")
                fieldset.modal__field
                  small
                    a(@click="removeScheduledUnavailability(index)") &times; Eliminar Ausencia programada

            .modal__row
              fieldset.modal__field
                span.days-error(v-if="!isValidScheduledUnavailavilities") 
            
            .modal__row.days__add
              fieldset.modal__field  
                el-button(@click="addScheduledUnavailability" :disabled="!isValidScheduledUnavailavilities" v-tooltip="isValidScheduledUnavailavilities ? '':'Debes completar la ausencia programada para agregar una nueva'") + Agregar Ausencia programada
      
      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="delete_forever")
            h3.sign__title Eliminar
          article.modal__fields
            .modal__row.days__add
              fieldset.modal__field
                el-button(type="danger" @click="deleteAgenda" :loading="isDeleting") Eliminar agenda
                
      ModalInstitution(
        v-if='isInstitutionModalVisible'
        :show-dialog-modal='isInstitutionModalVisible'
        @close='closeInstitutionModal'
      )
</template>

<style lang="scss" scoped>
.days {
  padding: 20px 0;
  border-bottom: 1px solid $light-gray;
}

.days-error {
  display: block;
  color: $flamingo;
  margin-left: 10px;
}

.days__add {
  margin: 20px 0;
}
</style>
