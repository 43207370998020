import configService from "./config";

const fileUploadConfigService = {};

fileUploadConfigService.getUploadAvatarEndpoint = function (type, configId) {
  return `${configService.apiUrl}/${type}/${configId}/avatar`;
};

// taskModel = vitals | recommendations | checkins, etc
// taskType = default | treatment | patient
fileUploadConfigService.getTaskMediaEndpoint = function (
  taskModel,
  taskType,
  taskID,
  mediaID = ""
) {
  return `${configService.apiUrl}/tasks/${taskModel}/type/${taskType}/${taskID}/media/${mediaID}`;
};

fileUploadConfigService.getEventFilesEndpoint = function (eventId) {
  return `${configService.apiUrl}/events/${eventId}/files`;
};

fileUploadConfigService.getIndicationFilesEndpoint = function (indicationId) {
  return `${configService.apiUrl}/indications/${indicationId}/files`;
};

fileUploadConfigService.getFormFilesEndpoint = function (formId) {
  return `${configService.apiUrl}/forms/${formId}/files`;
};

fileUploadConfigService.getMassiveActionEndpoint = function (type) {
  return `${configService.apiUrl}/massive/${type}`;
};

fileUploadConfigService.getPaymentFilesEndpoint = function (paymentIds, type) {
  return `${configService.apiUrl}/payments/${JSON.stringify(
    paymentIds
  )}/${type}`;
};

export default fileUploadConfigService;
