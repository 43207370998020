import { theme } from "@/services/config";

export const USER_ROLES = {
  ADMIN: {
    id: "000000000000000000000000",
    name: "Administrador",
    key: "admin",
  },
  MANAGER: {
    id: "000000000000000000000001",
    name: "Manager",
    key: "manager",
    alias: "Coordinador",
  },
  DOCTOR: { id: "000000000000000000000002", name: "Doctor", key: "doctor" },
  PATIENT: { id: "000000000000000000000003", name: "Paciente", key: "patient" },
  ASSISTANT: {
    id: "000000000000000000000004",
    name: "Asistente",
    key: "assistant",
  },
  SCORES: {
    id: "000000000000000000000005",
    name: "Scores",
    key: "scores",
  },
};

export const DASHBOARD_PERMISSIONS = {
  USER_VIEW: "user.view",
  TREATMENT_VIEW: "treatment.view",
  PATIENT_VIEW: "patient.view",
  ALERT_VIEW: "alert.view",
};

export const TASKS_TYPES = {
  vitals: {
    type: "vitals",
    name: "Registros",
    icon: "favorite",
    hasConfig: true,
    hasMedia: false,
  },
  reports: {
    type: "reports",
    name: "Reportes",
    icon: "local_pharmacy",
    hasConfig: true,
    hasMedia: false,
  },
  appointments: {
    type: "appointments",
    name: "Recordatorios",
    icon: "perm_contact_calendar",
    hasConfig: true,
    hasMedia: false,
  },
  checkins: {
    type: "checkins",
    name: "Check-in",
    icon: "location_on",
    hasConfig: true,
    hasMedia: false,
  },
  recommendations: {
    type: "recommendations",
    name: "Recomendaciones",
    icon: "healing",
    hasConfig: true,
    hasMedia: true,
  },
  exercises: {
    type: "exercises",
    name: "Ejercicios",
    icon: "fitness_center",
    hasConfig: true,
    hasMedia: true,
  },
  medications: {
    type: "medications",
    name: "Medicación",
    icon: "loyalty",
    hasConfig: true,
    hasMedia: false,
  },
};

export const EVENTS_TYPES = {
  "requires-new-service": {
    friendlyName: "Requiere nuevo servicio",
    status: "requires-new-service",
    // only the assistant can mark it as completed
    completedAt: null,
  },
  "patient-reviewed-with-contact": {
    friendlyName:
      theme === "albya" ? "Nota de Ingreso" : "Paciente revisado con llamado",
    status: "patient-reviewed-with-contact",
  },
  "patient-reviewed-without-contact": {
    friendlyName:
      theme === "albya" ? "Nota de Evolución" : "Paciente revisado sin llamado",
    status: "patient-reviewed-without-contact",
  },
  closed: {
    friendlyName: theme === "albya" ? "Nota de Egreso" : "Cerrado",
    status: "closed",
    options: [
      "Alta",
      "Derivado internación",
      "Socio no acepta monitoreo",
      "Óbito",
      "Sin criterio de monitoreo",
    ],
  },
};

export const INDICATIONS_TYPES = [
  {
    type: "medications",
    name: "Medicaciones",
    form: [
      {
        label: "drugName",
        friendlyName: "Nombre (Fármaco)",
        defaultValue: "",
        inputType: "input",
      },
      {
        label: "dosage",
        friendlyName: "Dosis (Cantidad)",
        defaultValue: "",
        inputType: "input",
      },
      {
        label: "frequency",
        friendlyName: "Frecuencia (hs)",
        defaultValue: 0,
        inputType: "input",
      },
      {
        label: "duration",
        friendlyName: "Plazo (días)",
        defaultValue: 0,
        inputType: "number",
      },
      {
        label: "observation",
        friendlyName: "Observaciones",
        defaultValue: "",
        inputType: "textarea",
      },
    ],
  },
  {
    type: "indications",
    name: "Indicaciones",
    form: [],
  },
  {
    type: "lab",
    name: "Laboratorio",
    form: [],
  },
  {
    type: "images",
    name: "Imágenes",
    form: [],
  },
  {
    type: "practices",
    name: "Prácticas",
    form: [],
  },
  {
    type: "diagnosis",
    name: "Diagnóstico",
    form: [],
  },
  {
    type: "evolution",
    name: "Evolución",
    form: [],
  },
  {
    type: "certificates",
    name: "Certificados",
    form: [],
  },
  {
    type: "epicrisis",
    name: "Epicrisis",
    form: [],
  },
];

export const PROVINCES = [
  "Buenos Aires",
  "CABA (Cuidad autónoma de Buenos Aires)",
  "Catamarca",
  "Chaco",
  "Chubut",
  "Córdoba",
  "Corrientes",
  "Entre Ríos",
  "Formosa",
  "Jujuy",
  "La Pampa",
  "La Rioja",
  "Mendoza",
  "Misiones",
  "Neuquén",
  "Río Negro",
  "Salta",
  "San Juan",
  "San Luis",
  "Santa Cruz",
  "Santa Fe",
  "Santiago del Estero",
  "Tierra del Fuego",
  "Tucumán",
];

export const APPOINTMENTS_TYPES_VADIGU = {
  // inPerson: { name: "Presencial", code: 1 },
  virtual: { name: "Virtual", code: 2 },
};

export const APPOINTMENTS_TYPES = {
  "in-person": { value: "in-person", friendlyName: "Presencial", code: 1 },
  virtual: { value: "virtual", friendlyName: "Virtual", code: 2 },
};

export const APPOINTMENTS_STATUS = {
  all: "Todas las citas",
  free: "Disponibles",
  busy: "Ocupados",
};

// Default tasks data

const media = {
  type: "image",
  content: [],
};

const basicConfig = {
  name: "",
  description: "",
  tags: [],
  measurement: {
    green: null,
    yellow: null,
    red: null,
  },
  links: [],
  shouldAlert: true,
};

const periodizationConfig = {
  type: "range",
  days: [],
  fromDay: 1,
  toDay: 30,
  hours: [],
  shouldNotify: true,
};

const recommendationDefault = {
  ...basicConfig,
  periodization: periodizationConfig,
  media,
  isFeatured: true,
  category: "",
};

const medicationDefault = {
  ...basicConfig,
  periodization: periodizationConfig,
  quantity: 0,
};

const exerciseDefault = {
  ...basicConfig,
  periodization: periodizationConfig,
  repetitions: 0,
  duration: "",
  series: 0,
  distance: 0,
  weight: 0,
  media,
};

const appointmentDefault = {
  ...basicConfig,
  periodization: periodizationConfig,
  category: "doctor",
};

const reportsDefault = {
  ...basicConfig,
  completedMeasurement: "yellow",
  isEditable: false,
  recommendations: [],
};

const checkinsDefault = {
  ...basicConfig,
  periodization: periodizationConfig,
  answerValues: [
    {
      question: "",
      answers: [
        {
          measurement: "green",
          type: "text",
          content: "",
        },

        {
          measurement: "red",
          type: "text",
          content: "",
        },
      ],
    },
  ],
};

const vitalsDefault = {
  ...basicConfig,
  periodization: periodizationConfig,
  answerValues: [
    {
      measurement: {
        green: {
          min: null,
          max: null,
        },
        yellow: {
          min: null,
          max: null,
        },
        red: {
          min: null,
          max: null,
        },
      },
    },
  ],
};

const defaultTasksData = {
  appointments: appointmentDefault,
  recommendations: recommendationDefault,
  medications: medicationDefault,
  exercises: exerciseDefault,
  reports: reportsDefault,
  checkins: checkinsDefault,
  vitals: vitalsDefault,
};

export function getDefaultTaskData(type) {
  const defaultConfig = defaultTasksData[type] || basicConfig;
  // Return object by value instead of reference
  return JSON.parse(JSON.stringify(defaultConfig));
}

export function getIndicationForm(type) {
  const indicationForm = INDICATIONS_TYPES.filter(
    (indication) => indication.type === type
  );
  return indicationForm[0];
}

export const DOC_TYPES =
  theme === "dash"
    ? ["curp"]
    : [
        "ci",
        "cuif",
        "cuil",
        "cuit",
        "dni",
        "iibb",
        "lc",
        "le",
        "pas",
        "usr",
        "var",
      ];

export const DOC_TYPE_DEFAULT = theme === "dash" ? "curp" : "dni";

export const LANGUAGES = [
  "Árabe",
  "Bengalí",
  "Chino Mandarín",
  "Español",
  "Francés",
  "Hindi",
  "Indonesio",
  "Inglés",
  "Portugués",
  "Ruso",
];

export const WEEK_DAYS_OPTIONS = [
  { name: "Domingo", value: 0 },
  { name: "Lunes", value: 1 },
  { name: "Martes", value: 2 },
  { name: "Miércoles", value: 3 },
  { name: "Jueves", value: 4 },
  { name: "Viernes", value: 5 },
  { name: "Sábado", value: 6 },
];

export const SLOT_ATTENTION_TYPES = [
  { name: "Virtual", value: "virtual" },
  { name: "Presencial", value: "in-person" },
];

export const SLOT_PERIODICITY = [
  { value: "weekly", name: "Semanalmente" },
  { value: "every-2-weeks", name: "Cada 2 semanas" },
  { value: "every-3-weeks", name: "Cada 3 semanas" },
  { value: "first-of-month", name: "Primero del mes" },
  { value: "second-of-month", name: "Segundo del mes" },
  { value: "third-of-month", name: "Tercero del mes" },
  { value: "fourth-of-month", name: "Cuarto del mes" },
  { value: "fifth-of-month", name: "Quinto del mes" },
];

export const massiveActionsTypes = [
  { value: "patient-creation", label: "Alta masiva de pacientes" },
  { value: "treatment-assignation", label: "Asignación masiva de protocolos" },
  { value: "recommendation-creation", label: "Alta masiva de recomendaciones" },
];

export const massiveActionsStatuses = [
  { value: "waiting", label: "En espera" },
  { value: "in-progress", label: "En progreso" },
  { value: "ended", label: "Finalizado" },
];
export const VIDEOROOM_MM_OFFSET_PRE = 60; // EXPRESSED IN MINUTES
export const VIDEOROOM_MM_OFFSET_POST = 240; // EXPRESSED IN MINUTES
